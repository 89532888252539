import axios from "axios";
import { merge } from "lodash";
import store from "@/store";
import util from "@/libs/util";
import Setting from "@/setting";
import { Message } from "element-ui";

// 创建一个 axios 实例
const service = axios.create({
  baseURL: Setting.request.apiBaseURL,
  timeout: Setting.request.timeout
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在请求发送之前做一些处理
    const token = util.cookies.get("token");
    console.log("%c Line:19 🥑 token", "background:#e41a6a", token);
    if (token) {
      // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
      config.headers["Authori-zation"] = "Bearer " + token;
      //   config.headers["Authori-identity"] = "Bearer " + Setting.identity;
      config.headers["Content-type"] = "application/json";
    }

    return merge(config, Setting.request.requestConfig(config, util));
  },
  (error) => {
    // 发送失败

    Promise.reject(error);
  }
);
function errorCreate(msg) {
  Message.error({
    message: msg
  });
  throw msg;
}
// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;
    // 这个状态码是和后端约定的
    const { code } = dataAxios;

    // 根据 code 进行判断
    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口
      errorCreate(`[ code: undefined ] `);
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (code) {
        case 200:
          return dataAxios.data;
          break
        case "400":
          // 未登录
          // [ 示例 ] 其它和后台约定的 code
          // Promise.reject(dataAxios);
          errorCreate(dataAxios.msg);

          break;

        default:
          // Promise.reject(dataAxios);
          errorCreate(dataAxios.msg);

          // 不是正确的 code
          break;
      }
    }
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = "请求错误";
          break;
        case 401:
          error.message = "未授权，请登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`;
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器内部错误";
          break;
        case 501:
          error.message = "服务未实现";
          break;
        case 502:
          error.message = "网关错误";
          break;
        case 503:
          error.message = "服务不可用";
          break;
        case 504:
          error.message = "网关超时";
          break;
        case 505:
          error.message = "HTTP版本不受支持";
          break;
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default service;
