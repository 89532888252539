import request from '@/plugins/request';

export function getStoreListApi(data) {
  return request({
    url: "/store_list",
    method: "post",
    data
  });
}
export function getProductListApi(data) {
  return request({
    url: "/product_list",
    method: "post",
    data
  });
}
export function getProductSkuAttrApi(data) {
  return request({
    url: "/product_sku_attr",
    method: "post",
    data
  });
}
export function getProductSkuListApi(data) {
  return request({
    url: "/product_sku",
    method: "post",
    data
  });
}
export function getProductDataApi(data) {
  return request({
    url: "/product",
    method: "post",
    data
  });
}
export function getUserAddressDataApi(data) {
  return request({
    url: "/user_address",
    method: "post",
    data
  });
}
export function getCityListApi(data) {
  return request({
    url: "/city_list",
    method: "post",
    data
  });
}
export function createOrderApi(data) {
  return request({
    url: "/create/order",
    method: "post",
    data
  });
}
