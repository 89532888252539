<template>
  <div class="">
    <div class="product" v-if="dialogFormVisible">
      <el-dialog title="" :visible.sync="dialogFormVisible" center width="1000px">
        <el-row>
          <el-col :span="10">
            <el-carousel v-if="product_data.info" :autoplay="false" trigger="click">
              <el-carousel-item v-for="(it, i) in product_data.info.slider_image" :key="i">
                <img class="slider_image_picture" :src="it" />
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col :span="14">
            <div class="product">
              <h1>{{ product_data.title }}</h1>
              <h2 v-if="order_data.price && order_data.price > 0">￥{{ order_data.price }}</h2>
              <h2 v-else>￥{{ product_data.exhibit_price }}</h2>

              <div class="sku_attr" v-for="(item, index) in product_sku_attr" :key="index">
                <span class="no_wrap">{{ item.attr_name }}：</span>
                <el-radio-group v-model="current_sku_attr[index]" fill="#fc3e46" @input="changeSkuAttr(item, index, $event)">
                  <el-radio-button :label="it" v-for="(it, i) in item.attr_value" :key="i"></el-radio-button>
                </el-radio-group>
              </div>

              <div class="order_num">
                <div>数量：</div>
                <div>
                  <el-input-number v-model="order_data.product_num" @change="changeNum" :min="1" :max="100"></el-input-number>
                </div>
              </div>

              <div class="address flex-layout cursor-pointer" @click="openAddress">
                <div class="no_wrap">配送地址：</div>
                <div>
                  <div class="order_user_address flex_align_center" v-if="order_data.consignee">
                    <div>
                      <div class="no_wrap">{{ order_data.consignee }} {{ order_data.phone }}</div>
                      <div class="no_wrap">{{ order_data.province_name }} - {{ order_data.city_name }}- {{ order_data.area_name }}</div>
                      <div class="no_wrap">{{ order_data.address }}</div>
                    </div>
                    <i class="arrow_address arrow right"></i>
                  </div>
                  <div class="order_user_address" v-else>
                    <div class="no_wrap">请选择地址</div>
                    <i class="arrow_address arrow right"></i>
                  </div>
                </div>
              </div>
              <div class="buttons">
                <button class="add purchase_btn" @click="addOrder">立即下单</button>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
    <Address ref="Address" @changeAddress="changeAddress"></Address>
  </div>
</template>

<script>
import { getProductSkuAttrApi, getProductSkuListApi, getProductDataApi, getUserAddressDataApi, createOrderApi } from "@/api/product";
import util from "@/libs/util";
import Address from "@/components/Address";
import { Loading } from "element-ui";

export default {
  name: "Product",
  components: {
    Address
  },
  //   props: {
  //     product_id: String
  //   },
  data() {
    return {
      order_data: {
        product_id: "",
        product_title: "",
        product_sku_id: "",
        sku: "",
        unique: "",
        price: "",
        product_num: 1,
        phone: "",
        consignee: "",
        province_name: "",
        city_name: "",
        area_name: "",
        address: "",
        address_id: "",
        product_price: ""
      },
      product_data: {},
      dialogFormVisible: false,
      orderLoading: false,
      product_id: "",
      product_sku_attr: [],
      current_sku_attr: [],
      product_sku_list: {}
    };
  },
  methods: {
    openModel(product_id) {
      this.product_id = product_id;
      this.order_data.product_id = product_id;

      this.product_data.product_id = product_id;

      this.dialogFormVisible = true;
      this.getProductSkuAttr();
      this.getProductSkuList();
      this.getProductData();
      this.getUserAddressData();
    },
    getProductData() {
      var productLoading = Loading.service();

      getProductDataApi({ product_id: this.product_id })
        .then((res) => {
          this.product_data = res;
          this.order_data.product_title = res.title;

          productLoading.close();
        })
        .catch((err) => {
          productLoading.close();
        });
    },
    getProductSkuAttr() {
      var loading = Loading.service();

      getProductSkuAttrApi({ product_id: this.product_id })
        .then((res) => {
          loading.close();

          this.product_sku_attr = res;
          var current_sku_attr = [];
          res.forEach((element) => {
            current_sku_attr.push("");
          });
          this.current_sku_attr = current_sku_attr;
        })
        .catch((err) => {
          loading.close();
        });
    },
    getProductSkuList() {
      var loading = Loading.service();

      getProductSkuListApi({ product_id: this.product_id })
        .then((res) => {
          this.product_sku_list = res;
          loading.close();
        })
        .catch((err) => {
          loading.close();
        });
    },
    createOrder() {},
    changeSkuAttr(item, index, e) {
      var sku = this.current_sku_attr.join(",");
      this.product_sku_list.forEach((element) => {
        if (element.sku == sku) {
          this.product_data.price = element.exhibit_price;
          this.order_data.product_sku_id = element.id;
          this.order_data.sku = sku;
          this.order_data.unique = element.unique;
          this.order_data.product_price = element.exhibit_price;

          this.changePrice();
        }
      });
    },
    changeNum(e) {
      this.order_data.product_num = e;
      this.current_sku_attr.forEach((element) => {
        if (element == "") {
          this.$message.error("请选择产品规格");

          return;
        }
      });
      this.changePrice();
    },
    changePrice() {
      if (this.product_data.product_sku_id) {
        this.$message.error("请选择产品规格");
        return;
      }
      this.order_data.price = (parseFloat(this.order_data.product_num) * parseFloat(this.product_data.price)).toFixed(2);
    },
    getUserAddressData() {
      getUserAddressDataApi().then((res) => {
        this.orderAddress(res.id, res.phone, res.consignee, res.province_name, res.city_name, res.area_name, res.address);
      });
    },
    orderAddress(id, phone, consignee, province_name, city_name, area_name, address) {
      this.order_data.phone = phone;
      this.order_data.consignee = consignee;
      this.order_data.province_name = province_name;
      this.order_data.city_name = city_name;
      this.order_data.area_name = area_name;
      this.order_data.address = address;
      this.order_data.address_id = id;
    },
    changeAddress(address) {
      this.orderAddress(address.id, address.phone, address.consignee, address.province_name, address.city_name, address.area_name, address.address);
    },
    openAddress() {
      this.$refs.Address.openModel(this.order_data.address_id);
    },
    addOrder() {
      this.$message.error("支付申请中，暂未开通");

      return true;
      var loading = Loading.service();

      createOrderApi(this.order_data)
        .then((res) => {
          loading.close();
        })
        .catch((err) => {
          loading.close();
        });
    }
  }
};
</script>

<style scoped>
/* center container in the middle */
html {
  display: grid;
  min-height: 100%;
}

body {
  display: grid;
  background: #e0c9cb;
  text-transform: uppercase;
}

.container {
  position: relative;
  margin: auto;
  overflow: hidden;
  /* width: 520px;
  height: 350px;
  background: #f5f5f5; */
  /* box-shadow: 5px 5px 15px rgba(#ba7e7e, 0.5); */
  border-radius: 10px;
}

/* p {
  font-size: 0.6em;
  color: #ba7e7e;
  letter-spacing: 1px;
} */

h1 {
  font-size: 1.2em;
  color: #4e4e4e;
  margin-top: -5px;
}

h2 {
  color: #fc3e46;
}

/* img {
  width: 290px;
  margin-top: 47px;
} */

.slideshow-buttons {
  top: 70%;
  display: none;
}

/* .one,
.two,
.three,
.four {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #e0c9cb;
}

.one {
  left: 22%;
}
.two {
  left: 27%;
}
.three {
  left: 32%;
}
.four {
  left: 37%;
} */

.product {
  /* position: absolute; */
  /* width: 40%; */
  height: 100%;
  /* top: 10%;
  left: 60%; */
  margin-left: 50px;
}

/* button {
  background: darken(#e0c9cb, 10%);
  padding: 10px;
  display: inline-block;
  outline: 0;
  border: 0;
  margin: -1px;
  border-radius: 2px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #f5f5f5;
  cursor: pointer;
} */

.add {
  width: 67%;
}

.like {
  width: 22%;
}

.sizes {
  display: grid;
  color: #d9aab7;
  grid-template-columns: repeat(auto-fill, 30px);
  width: 60%;
  grid-gap: 4px;
  margin-left: 20px;
  margin-top: 5px;
}

.pick {
  margin-top: 11px;
  margin-bottom: 0;
  margin-left: 20px;
}

.size {
  padding: 9px;
  border: 1px solid #e0c9cb;
  font-size: 0.7em;
  text-align: center;
}

/* .focus {
  background: #ba7e7e;
  color: #f5f5f5;
} */
/* 
footer {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.8em;
  text-transform: uppercase;
  padding: 10px;
} */
/* img {
  width: 100%;
  margin: 0;
} */
.sku_attr {
  margin: 20px 0;
  display: flex;
}

.order_num {
  display: ruby-text;
  margin: 20px 0;
}

.address_title {
  margin-top: 20rpx;
  font-weight: bold;
  font-size: 31rpx;
  color: #2e4f76;
  padding-bottom: 20rpx;
  border-bottom: 1px solid #2e4f76;
}
.address_info {
  margin-top: 20rpx;
}
.address_info_item {
  margin-top: 20rpx;
}
.picker_info {
  border-width: 0.5px !important;
  border-color: #dadbde !important;
  border-style: solid;
  padding: 6px 9px;
  border-radius: 4px;
  height: 24px;
}
.address_text {
  color: #c0c4cc;
}
.arrow_address {
  margin-left: 50px;
}
.arrow {
  border: solid #dadbde;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  width: 2px;
  height: 2px;
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.order_address {
  width: 100%;
  margin-bottom: 20px;
}
.order_address_desc {
  /* word-wrap: normal; */
  /* color: #dcdfe6; */
  white-space: nowrap;
}
.address {
  margin-bottom: 20px;
}
.order_user_address {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.purchase_btn {
  border: none;
  padding: 12px 24px;
  border-radius: 50px;

  font-weight: 600;
  /* color: #fc3e46; */
  color: #fff;
  background-color: #fc3e46;

  /* margin: 0 auto; */
  /* display: block; */

  cursor: pointer;
}
/* .el-radio-button__inner {
  background-color: aqua;
  border-left: none;
  margin: 5px;
} */
.el-radio-button {
  border-left: 1px solid #dadbde;
  margin: 5px;
}
.el-radio-button__inner {
  border: none;
}
</style>
