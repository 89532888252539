import cookies from './util.cookies.js';

import Setting from '@/setting';

const util = {
    cookies,
};



  
function requestAnimation (task) {
    if ('requestAnimationFrame' in window) {
        return window.requestAnimationFrame(task);
    }

    setTimeout(task, 16);
}

export { requestAnimation };

export default util;
