import request from '@/plugins/request';

export function userLogin (data) {
    return request({
        url: '/login',
        method: 'post',
        data
    });
}
export function getUserLoginCode(data) {
  return request({
    url: "/login/code",
    method: "post",
    data
  });
}
export function test(data) {
  return request({
    url: "/test",
    method: "post",
    data
  });
}
export function saveUserAddressApi(data) {
  return request({
    url: "/user_address/save",
    method: "post",
    data
  });
}
export function getUserAddressListApi(data) {
  return request({
    url: "/user_address_list",
    method: "post",
    data
  });
}
export function updateDefaultAddressApi(data) {
  return request({
    url: "/user_default_address/update",
    method: "post",
    data
  });
}