/**
 * Admin Plus 业务配置
 * */

const env = process.env.NODE_ENV;

const Setting = {
  // 页面切换时，是否显示模拟的进度条
  showProgressBar: true,
  // Cookies 默认保存时间，单位：天，0 为 session
  cookiesExpires: 1,
  /**
   * 请求数据配置
   * */
  request: {
    // 接口请求地址
    // apiBaseURL: "http://juhui.com/pcapi",
    apiBaseURL: "https://api.qishengliang.com/pcapi",

    // 请求超时时间
    timeout: 20000,
    // 接口请求返回错误时，弹窗的持续时间，单位：秒
    modalDuration: 10,
    // 接口请求返回错误时，弹窗的类型，可选值为 Message 或 Notice
    errorModalType: "Message",
    // 请求拦截器配置
    requestConfig(config, util) {
      return {};
    }
  }
};

export default Setting;
